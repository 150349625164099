import http from "../../../utils/http";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchUsers = createAsyncThunk("/user/details", async () => {
  const { data } = await http.get("/api/user/All");
  return data;
});

export const fetchVendors = createAsyncThunk("/vendor/details", async () => {
  const { data } = await http.get("/api/admin/getVendors");
  return data;
});

export const fetchPointsAdded = createAsyncThunk(
  "/user/addedPoints",
  async () => {
    const { data } = await http.get("/api/admin/addedPoints");
    return data;
  }
);

export const fetchCreditsAdded = createAsyncThunk(
  "/user/addedCredits",
  async () => {
    const { data } = await http.get("/api/admin/addedCredits");
    return data;
  }
);

export const fetchPointsTransfered = createAsyncThunk(
  "/user/transferPoints",
  async () => {
    const { data } = await http.get("/api/admin/transferPoints");
    return data;
  }
);

export const fetchPointsRedeemed = createAsyncThunk(
  "/user/RedeemedPoints",
  async () => {
    const { data } = await http.get("/api/admin/redeemPoints");
    return data;
  }
);
export const fetchVendorTransaction = createAsyncThunk(
  "/vendor/TransactionHistory",
  async () => {
    const { data } = await http.get("/api/vendor/redeemPoints");
    return data;
  }
);

export const fetchSearchedUser = createAsyncThunk(
  "/user/searched",
  async (cardNo) => {
    try {
      const { data } = await http.get(`/api/admin/searchUser/${cardNo}`);
      return data;
    } catch (err) {
      const error = err.response.data;
      return error;
    }
  }
);
