import { createSlice } from "@reduxjs/toolkit";
import {
  addAllUserPoints,
  addUserCredits,
  addUserPoints,
  exceedCreditLimit,
  makeVendorTransaction,
  registerUser,
  registerVendor,
  removeUser,
  removeVendor,
  updateUser,
  updateVendor,
  updateVendorTransaction,
} from "../asyncThunk";

const initialState = {
  registerUserResponse: {},
  removeUserResponse: {},
  updateUserResponse: {},
  registerVendorResponse: {},
  removeVendorResponse: {},
  updateVendorResponse: {},
  vendorTransactionResponse: {},
  updateTransactionResponse: {},
  adminTransactionResponse: {},
  allUserTransactionResponse: {},
  adminCreditTransactionResponse: {},
  exceedCreditLimitResponse: {},
};

const post = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: {
    [registerUser.fulfilled]: (state, { payload }) => {
      state.registerUserResponse = payload;
    },
    [registerVendor.fulfilled]: (state, { payload }) => {
      state.registerVendorResponse = payload;
    },
    [addUserPoints.fulfilled]: (state, { payload }) => {
      state.adminTransactionResponse = payload;
    },
    [addAllUserPoints.fulfilled]: (state, { payload }) => {
      state.allUserTransactionResponse = payload;
    },
    [addUserCredits.fulfilled]: (state, { payload }) => {
      state.adminCreditTransactionResponse = payload;
    },
    [exceedCreditLimit.fulfilled]: (state, { payload }) => {
      state.exceedCreditLimitResponse = payload;
    },
    [removeUser.fulfilled]: (state, { payload }) => {
      state.removeUserResponse = payload;
    },
    [removeVendor.fulfilled]: (state, { payload }) => {
      state.removeVendorResponse = payload;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.updateUserResponse = payload;
    },
    [updateVendor.fulfilled]: (state, { payload }) => {
      state.updateVendorResponse = payload;
    },
    [makeVendorTransaction.fulfilled]: (state, { payload }) => {
      state.vendorTransactionResponse = payload;
    },
    [updateVendorTransaction.fulfilled]: (state, { payload }) => {
      state.updateTransactionResponse = payload;
    },
  },
});

export default post.reducer;
