const { createSlice } = require("@reduxjs/toolkit");
const {
  fetchUsers,
  fetchPointsAdded,
  fetchVendors,
  fetchPointsTransfered,
  fetchPointsRedeemed,
  fetchSearchedUser,
  fetchCreditsAdded,
  fetchVendorTransaction,
} = require("../asyncThunk");

const initialState = {
  usersDetails: [],
  vendorDetails: [],
  pointsTransfered: [],
  pointsAdded: [],
  creditsAdded: [],
  pointsRedeemed: [],
  vendorTransactionHistory: [],
  userData: [],
};

const fetch = createSlice({
  name: "fetch",
  initialState,
  reducer: {},
  extraReducers: {
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.usersDetails = payload;
    },
    [fetchVendors.fulfilled]: (state, { payload }) => {
      state.vendorDetails = payload;
    },
    [fetchPointsAdded.fulfilled]: (state, { payload }) => {
      state.pointsAdded = payload;
    },
    [fetchCreditsAdded.fulfilled]: (state, { payload }) => {
      state.creditsAdded = payload;
    },
    [fetchPointsTransfered.fulfilled]: (state, { payload }) => {
      state.pointsTransfered = payload;
    },
    [fetchPointsRedeemed.fulfilled]: (state, { payload }) => {
      state.pointsRedeemed = payload;
    },
    [fetchVendorTransaction.fulfilled]: (state, { payload }) => {
      state.vendorTransactionHistory = payload;
    },
    [fetchSearchedUser.fulfilled]: (state, { payload }) => {
      state.userData = payload;
    },
  },
});

export default fetch.reducer;
