import toast from "react-hot-toast";
import http from "../../../utils/http";

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const registerUser = createAsyncThunk(
  "/user/register",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/addUser", payload);
      toast.success(data.message);
      return data;
    } catch (err) {
      const error = err.response.data;
      toast.error(error.data.message);
      return error;
    }
  }
);

export const updateUser = createAsyncThunk(
  "/user/update",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/updateUser", payload);
      toast.success(data.message);
      return data;
    } catch (err) {
      const error = err.response.data;
      toast.error(error.data.message);
      return error;
    }
  }
);

export const registerVendor = createAsyncThunk(
  "/vendor/register",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/addVendor", payload);
      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const updateVendor = createAsyncThunk(
  "/vendor/update",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/updateVendor", payload);

      return data;
    } catch (err) {
      const error = err.response.data;
      return error;
    }
  }
);

export const makeVendorTransaction = createAsyncThunk(
  "/vendor/transaction",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/vendor/transaction", payload);
      return data;
    } catch (err) {
      const error = err.response.data;
      return error;
    }
  }
);

export const updateVendorTransaction = createAsyncThunk(
  "/vendor/transaction/update",

  async ({ payload }) => {
    try {
      const { data } = await http.post(
        "/api/vendor/updateTransaction",
        payload
      );
      toast.success(data.message);
      return data;
    } catch (err) {
      const error = err.response.data;
      toast.error(error.message);
      return error;
    }
  }
);

export const addUserPoints = createAsyncThunk(
  "/user/addPoints",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/transaction", payload);

      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const addAllUserPoints = createAsyncThunk(
  "/user/addAllUserPoints",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/transactionAll", payload);

      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const addUserCredits = createAsyncThunk(
  "/user/addCredits",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/creditTransaction", payload);

      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const exceedCreditLimit = createAsyncThunk(
  "/user/exceedCreditLimit",

  async ({ payload }) => {
    try {
      const { data } = await http.post("/api/admin/exceedlimit", payload);

      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const removeUser = createAsyncThunk(
  "/user/removed",

  async (cardNo) => {
    try {
      const { data } = await http.get(`/api/admin/removeUser/${cardNo}`);

      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);

export const removeVendor = createAsyncThunk(
  "/vendor/removed",

  async (vendorId) => {
    try {
      const { data } = await http.get(`/api/admin/removeVendor/${vendorId}`);
      return data;
    } catch (err) {
      const error = err.response.data;

      return error;
    }
  }
);
